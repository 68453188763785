import { useCallback, useEffect, useState } from 'react';
import { Stack } from '@mui/material/';
import { useAppStore } from '../store/AppStore';
import { useLocation } from 'react-router-dom';
import { ErrorBoundary, AppIconButton } from '../components';
import { useOnMobile } from '../hooks/layout';
import { BOTTOMBAR_DESKTOP_VISIBLE, TOPBAR_DESKTOP_HEIGHT, TOPBAR_MOBILE_HEIGHT } from './config';
import { useEventSwitchDarkMode } from '../hooks/event';
import TopBar from './TopBar';
import LogoIcon from '../assets/logo/avmainlogo.png';
import SideBar from './SideBar';
import BottomBar from './BottomBar';

// TODO: change to your app name or other word
const TITLE_PUBLIC = 'ARTISTVERIFIED'; // Title for pages without/before authentication

/**
 * SideBar navigation items with links
 */
const SIDEBAR_ITEMS = [
  {
    title: 'Log In',
    path: '/auth/login',
    icon: 'login',
  },
  {
    title: 'Sign Up',
    path: '/auth/signup',
    icon: 'signup',
  },
  {
    title: 'About',
    path: '/about',
    icon: 'info',
  },
];

/**
 * BottomBar navigation items with links
 */
const BOTTOMBAR_ITEMS = [
  {
    title: 'Log In',
    path: '/auth/login',
    icon: 'login',
  },
  {
    title: 'Sign Up',
    path: '/auth/signup',
    icon: 'signup',
  },
  {
    title: 'About',
    path: '/about',
    icon: 'info',
  },
];

/**
 * Renders "Public Layout" composition
 * @component PublicLayout
 */
const PublicLayout = ({ children }) => {
  const onMobile = useOnMobile();
  const onSwitchDarkMode = useEventSwitchDarkMode();
  const [sideBarVisible, setSideBarVisible] = useState(false);
  const location = useLocation();
  const [state] = useAppStore();
  const topBarVisible = state.showTopBarPageList?.includes(location.pathname) ? true : false;
  // Variant 1 - Sidebar is static on desktop and is a drawer on mobile
  // const sidebarOpen = onMobile ? sideBarVisible : true;
  // const sidebarVariant = onMobile ? 'temporary' : 'persistent';

  // Variant 2 - Sidebar is drawer on mobile and desktop
  const sidebarOpen = sideBarVisible;
  const sidebarVariant = 'temporary';

  const title = TITLE_PUBLIC;
  document.title = title; // Also Update Tab Title

  const onSideBarOpen = useCallback(() => {
    if (!sideBarVisible) setSideBarVisible(true); // Don't re-render Layout when SideBar is already open
  }, [sideBarVisible]);

  const onSideBarClose = useCallback(() => {
    if (sideBarVisible) setSideBarVisible(false); // Don't re-render Layout when SideBar is already closed
  }, [sideBarVisible]);
  useEffect(() => {
    const hash = window.location.hash
      .substring(1)
      .split('&')
      .reduce((initial, item) => {
        if (item) {
          const parts = item.split('=');
          initial[parts[0]] = decodeURIComponent(parts[1]);
        }
        return initial;
      }, {});
    console.log(hash, 'window hash');
    window.location.hash = '';
    if (hash.access_token) {
      console.log(hash, 'hash');
      localStorage.setItem('spotifyToken', hash.access_token);
    }
  }, []);
  console.log(location);
  // console.log(
  //   'Render using PublicLayout, onMobile:',
  //   onMobile,
  //   'sidebarOpen:',
  //   sidebarOpen,
  //   'sidebarVariant:',
  //   sidebarVariant
  // );

  return (
    <Stack
      sx={{
        minHeight: '100vh', // Full screen height
        paddingTop: topBarVisible ? (onMobile ? TOPBAR_MOBILE_HEIGHT : TOPBAR_DESKTOP_HEIGHT) : 0,
      }}
    >
      {/* <Stack component="header"> */}
      {/* {topBarVisible && (
        <TopBar
          startNode={
            <img src={LogoIcon} width={'25'} height={'25'} alt="logo" onClick={onSideBarOpen} />
          }
          title={title}
          toolbarSx={{ diplay: 'flex', justifyContent: 'center', alignItems: 'center' }}
        />
      )} */}

      {/* <SideBar
          anchor="left"
          open={sidebarOpen}
          variant={sidebarVariant}
          items={SIDEBAR_ITEMS}
          onClose={onSideBarClose}
        /> */}
      {/* </Stack> */}

      <Stack
        component="main"
        sx={{
          flexGrow: 1, // Takes all possible space
          padding: 1,
        }}
      >
        <ErrorBoundary name="Content">{children}</ErrorBoundary>
      </Stack>
    </Stack>
  );
};

export default PublicLayout;
