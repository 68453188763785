import { localStorageSet } from '../utils/localStorage';
import {
  DARK_MODE,
  LOG_IN,
  LOG_OUT,
  SET_CURRENT_USER,
  SET_ERROR,
  SET_USER_IP_DATA,
  SIGN_UP,
} from './Actions';

/**
 * Main reducer for global AppStore using "Redux styled" actions
 * @function AppReducer
 * @param {object} state - current/default state
 * @param {string} action.type - unique name of the action
 * @param {*} [action.payload] - optional data object or the function to get data object
 */
const AppReducer = (state, action) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      console.log(action?.currentUser || action?.payload, 'payload');
      return {
        ...state,
        currentUser: action?.currentUser || action?.payload,
      };
    case SET_USER_IP_DATA:
      return {
        ...state,
        userIpData: action?.userIpData || action?.payload,
      };
    case SIGN_UP:
    case LOG_IN:
      return {
        ...state,
        isAuthenticated: true,
        currentUser: action?.payload,
      };
    case LOG_OUT:
      return {
        ...state,
        isAuthenticated: false,
        currentUser: undefined, // Also reset previous user data
      };
    case DARK_MODE: {
      const darkMode = action?.darkMode ?? action?.payload;
      localStorageSet('darkMode', darkMode);
      return {
        ...state,
        darkMode,
      };
    }
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default AppReducer;
