import { supabase } from '../SupabaseClient';
import { api } from '../..';
import { getGlobalState } from '../../../store/AppStore';
import { error } from 'loglevel';

export const subscribeMostFavouriteArtist = async (userId, artist) => {
  try {
    await api.suprsend.subscribeUserToFavouriteArtist(
      userId,
      artist.rtsvrf_artist_id + '_number1',
      {
        list_name: `${artist?.artist_name}_number1`,
        list_description: `${artist?.artist_name}_number1`,
      }
    );
    await api.suprsend.subscribeUserToFavouriteArtist(userId, artist.rtsvrf_artist_id + '_top5', {
      list_name: `${artist?.artist_name}_top5`,
      list_description: `${artist?.artist_name}_top5`,
    });
    return { sucess: true };
  } catch (error) {
    return { error: true, message: error };
  }
};

export const subscribeFavouriteArtist = async (userId, artist) => {
  try {
    await api.suprsend.subscribeUserToFavouriteArtist(userId, artist.rtsvrf_artist_id + '_top5', {
      list_name: `${artist?.artist_name}_top5`,
      list_description: `${artist?.artist_name}_top5`,
    });
    return { sucess: true };
  } catch (error) {
    return { error: true, message: error };
  }
};

export const unsubscribeMostFavouriteArtist = async (artistId) => {
  const state = getGlobalState();
  if (!state?.currentUser) return [];
  const user = state.currentUser;

  try {
    await api.suprsend.unsubscribeUserFromArtist(user.rtsvrf_fan_uuid, artistId + '_number1');
    await api.suprsend.unsubscribeUserFromArtist(user.rtsvrf_fan_uuid, artistId + '_top5');
    return { sucess: true };
  } catch (error) {
    return { error: true, message: error };
  }
};

export const unsubscribeFavouriteArtist = async (artistId) => {
  const state = getGlobalState();
  if (!state?.currentUser) return [];
  const user = state.currentUser;

  try {
    await api.suprsend.unsubscribeUserFromArtist(user.rtsvrf_fan_uuid, artistId + '_top5');
    return { sucess: true };
  } catch (error) {
    return { error: true, message: error };
  }
};

export const subscribeToFollowedArtist = async (userId, artist) => {
  try {
    await api.suprsend.subscribeUserToFavouriteArtist(userId, artist.rtsvrf_artist_id, {
      list_name: `${artist.artist_name}_all_followers`,
      list_description: `${artist.artist_name}_all_followers`,
    });
    return { sucess: true };
  } catch (error) {
    return { error: true, message: error };
  }
};

export const unsubscribeUserFromArtist = async (
  userId,

  artistId
) => {
  if (!userId || !artistId) return;

  try {
    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({}),
    };
    fetch(
      `${process.env.REACT_APP_INTERNAL_SERVER}/${userId}/unsubscribe/artist/${artistId}/single`,
      options
    )
      .then((response) => response.json())
      .then((response) => console.log(response))
      .catch((err) => console.error(err));
    return { sucess: true };
  } catch (error) {
    console.error('Error unsubscribing user from an artist:', error);
    return { error: true, message: error };
  }
};

export const getUserSubscription = async () => {
  const state = getGlobalState();
  if (!state?.currentUser) return { error: true, message: 'User not found' };
  const user = state.currentUser;

  const results = await supabase
    .from('tbl_staged_fan_subsdata')
    .select()
    .eq('rtsvrf_fan_uuid', user.rtsvrf_fan_uuid)
    .single();
  return results.data ?? null;
};
