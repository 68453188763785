import { supabase } from '../SupabaseClient';
import { api } from '../..';
import { userNotificationMapping } from '../../../utils/constants';
import { getGlobalDispatch, getGlobalState } from '../../../store/AppStore';
import { SET_CURRENT_USER } from '../../../store/Actions';
import suprsend from '@suprsend/web-sdk';
import { isArrayEqual } from '../../../utils/helpers';

export const getFanProfile = async (email) => {
  if (!email) return;
  const results = await supabase
    .from('tbl_staged_fan_user_profile')
    .select()
    .eq('fan_email', email)
    .single();
  return results.data;
};

export const insertProfileData = async (data) => {
  if (!data) return;
  let results = await supabase.from('tbl_staged_fan_user_profile').insert(data);
  return results;
};

export const getUserData = async (profile, userIpData) => {
  if (!profile || !profile.fan_email) return;
  let user = await getFanProfile(profile.fan_email);

  if (!user) {
    await insertProfileData(profile);
    user = await getFanProfile(profile.fan_email);
    if (user) {
      await api.log.insert('Sign Up', {
        ...userIpData,
        email: profile.fan_email,
        timestamp: new Date(),
        type: 'Fan',
        details: profile.fan_source_platform,
      });
      if (!user.suprsend_subscriber_id) {
        const suprsend_subscriber_id = await api.suprsend.getUserSubscriberKey(
          user.rtsvrf_fan_uuid
        );
        if (suprsend_subscriber_id) {
          await updateProfileData(user.rtsvrf_fan_uuid, {
            suprsend_subscriber_id: suprsend_subscriber_id,
          });
        }
        user = { ...user, suprsend_subscriber_id };
      }
      await api.suprsend.notifyUser(user.rtsvrf_fan_uuid, {
        details: { UserName: user.fan_name },
        type: userNotificationMapping.welcome,
        name: `${userNotificationMapping.welcome}${user.fan_name || user.fan_email}`,
      });
    }
    return user;
  } else {
    if (!user.suprsend_subscriber_id) {
      const suprsend_subscriber_id = await api.suprsend.getUserSubscriberKey(user.rtsvrf_fan_uuid);
      if (suprsend_subscriber_id) {
        await updateProfileData(user.rtsvrf_fan_uuid, {
          suprsend_subscriber_id: suprsend_subscriber_id,
        });
      }
      user = { ...user, suprsend_subscriber_id };
    }
    return user;
  }
};

export const getFanCustomNewsfeed = async () => {
  const state = getGlobalState();
  const user = state.currentUser;
  if (!user) {
    return { error: true, message: 'User not found' };
  }
  const results = await supabase
    .from('tbl_staged_fan_custom_news')
    .select()
    .eq('rtsvrf_fan_uuid', user.rtsvrf_fan_uuid)
    .single();

  const defaultData = {
    rtsvrf_fan_uuid: user.rtsvrf_fan_uuid,
    custom_artist_selection: false,
    custom_artist_activities: false,
    custom_artist_source: false,
    custom_artist_list: [],
    custom_activity_list: [],
    custom_source_list: [],
  };
  return results.data ?? defaultData;
};

export const updateFanCustomNewsfeed = async (data) => {
  if (!data) return;
  const results = await supabase.from('tbl_staged_fan_custom_news').upsert({
    ...data,
    updated_at: new Date(),
  });
  return results;
};

export const updateProfileData = async (id, data) => {
  if (!data || !id) return;
  const updateData = { ...data, updated_at: new Date() };
  let results = await supabase
    .from('tbl_staged_fan_user_profile')
    .update({
      ...updateData,
    })
    .eq('rtsvrf_fan_uuid', id);
  const dispatch = getGlobalDispatch();
  const state = getGlobalState();
  if (state.currentUser)
    dispatch({ type: SET_CURRENT_USER, payload: { ...state.currentUser, ...updateData } });

  return results;
};

export const getFanProfileById = async (id) => {
  if (!id) return;
  const results = await supabase
    .from('tbl_staged_fan_user_profile')
    .select()
    .eq('rtsvrf_fan_uuid', id)
    .single();
  return results.data;
};

export const editUserProfile = async (profile) => {
  const state = getGlobalState();
  const user = state.currentUser;
  const userIpData = state.userIpData;
  if (!user) {
    return { error: true, message: 'User not found' };
  }
  await api.GA.sendEventData('Save profile', 'click', 'Save profile');

  const difference = Object.keys(profile).filter(
    (k) =>
      profile[k] !== user[k] &&
      k !== 'spotify_followed_artists_id' &&
      k !== 'apple_followed_artists_id'
  );
  if (difference?.includes('fan_name')) {
    if (profile.fan_name) suprsend.user.set('name', profile.fan_name);
    else suprsend.user.unset('name');
  }
  if (difference?.includes('phone_number') || difference?.includes('dial_prefix')) {
    if (profile.phone_number && profile.dial_prefix)
      suprsend.user.add_sms(profile.dial_prefix + profile.phone_number);
    else suprsend.user.remove_sms(user.dial_prefix + user.phone_number);
  }

  const details =
    difference?.map((field) => `${field} changed from ${user[field]} to ${profile[field]}`) || [];
  await api.log.insert(`Profile updated - ${user?.fan_name}`, {
    ...userIpData,
    email: user?.fan_email,
    timestamp: new Date(),
    type: 'Fan',
    details: details.join(','),
  });
  const result = await updateProfileData(user.rtsvrf_fan_uuid, profile);

  if (!result.error) {
    return { success: true, message: 'Profile updated successfully' };
  } else {
    return { error: true, message: result.error?.message ?? 'Profile not updated' };
  }
};

export const editUserSettings = async (profile, newsFeed, customNewsFeed, customArtistList) => {
  await api.GA.sendEventData('Save profile', 'click', 'Save profile');
  const state = getGlobalState();
  const user = state.currentUser;
  const userIpData = state.userIpData;
  if (!user) {
    return { error: true, message: 'User not found' };
  }

  const settingsDifference = [
    'data_sharing_preference',
    'homepage_preference',
    'messaging_preference',
    'artists_sync_frequency',
  ].filter((k) => profile[k] !== user[k]);
  const settingsDetails =
    settingsDifference?.map(
      (field) => `${field} changed from ${user[field]} to ${profile[field]}`
    ) || [];

  const newsFeedDifference = Object.keys(newsFeed).filter((k) => newsFeed[k] !== customNewsFeed[k]);
  const newsFeedDetails =
    newsFeedDifference?.map(
      (field) => `${field} changed from ${customNewsFeed[field]} to ${newsFeed[field]}`
    ) || [];
  await api.log.insert(`Settings updated - ${user?.fan_name}`, {
    ...userIpData,
    email: user?.fan_email,
    timestamp: new Date(),
    type: 'Fan',
    details: `${[...newsFeedDetails, ...settingsDetails].join(',')}, ${
      !isArrayEqual(customNewsFeed.custom_artist_list, customArtistList)
        ? `custom_artist_list changed from ${customNewsFeed.custom_artist_list} to ${customArtistList}`
        : ''
    }, ${
      !isArrayEqual(user?.custom_channel_list, profile?.custom_channel_list)
        ? `custom_channel_list changed from ${user.custom_channel_list} to ${profile.custom_channel_list}`
        : ''
    }`,
  });

  const result = await updateProfileData(user.rtsvrf_fan_uuid, profile);
  const newsResult = await updateFanCustomNewsfeed({
    ...newsFeed,
    custom_artist_list: customArtistList,
  });

  if (!result.error && !newsResult.error) {
    return { success: true, message: 'Profile updated successfully' };
  } else {
    return { error: true, message: result.error?.message ?? 'Profile not updated' };
  }
};

export const uploadImageToBucket = async (selectedImage, arrayBuffer) => {
  const time = new Date().getTime();
  const { data, error } = await supabase.storage
    .from('bkt_staged_fan_profile')
    .upload(`public/${selectedImage.name}_${time}`, arrayBuffer, {
      contentType: selectedImage.type,
    });

  if (error) {
    return { error: true, message: error };
  } else {
    const res = supabase.storage.from('bkt_staged_fan_profile').getPublicUrl(data.path);
    return (
      res?.data?.publicUrl ?? {
        error: true,
        message: 'Failed to upload an image',
      }
    );
  }
};

export const saveUserProfileImage = async (data) => {
  await api.GA.sendEventData('Uplade profile picture', 'click', 'Uplade profile picture');
  const state = getGlobalState();
  const user = state.currentUser;
  const userIpData = state.userIpData;
  if (!user) {
    return { error: true, message: 'User not found' };
  }

  await updateProfileData(user.rtsvrf_fan_uuid, { fan_picture_link: data });
  await api.log.insert(`Profile picture updated - ${user?.fan_name || ''}`, {
    ...userIpData,
    email: user?.fan_email,
    timestamp: new Date(),
    type: 'Fan',
    details: `Profile picture updated via app`,
  });
  return { success: true, message: 'Profile picture updated successfully' };
};
