import { api } from '..';

export async function getIpData() {
  try {
    const res = await api?.axios?.get(`https://api.ipgeolocation.io/ipgeo?apiKey=${process.env.REACT_APP_IP_KEY}`);
    return res?.data;
  } catch (error) {
    log.error(error);
  }
  return undefined;
}

export default getIpData;
