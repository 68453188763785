import { SPOTIFY_UNKNOWN, SPOTIFY_LINK } from '../../../utils/constants';
import { getTimeStampString } from '../../../utils/helpers';
import { api } from '../..';

export const updateSpotifyArtistsByFlywheel = async (
  artists,
  userId,
  userIpData,
  platformOrigin
) => {
  if (!artists || !artists.length) return;
  const artistIds = artists?.map((artist) => artist.id) ?? [];

  const id = 'artist_spotfy_id';
  const savedArtists = await api.db.artists.getArtistsBySpecificId(artistIds, id);
  if (savedArtists?.length !== artistIds.length && artists) {
    const missingArtistIds = [];
    for (let index = 0; index < artists.length; index++) {
      const artist = artists[index];
      const artistName = artist?.name;
      if (!savedArtists?.find((e) => e[id] === artist.id || e?.artist_name === artistName)) {
        missingArtistIds.push(artist.id);
        await api.db.artists.insertArtistBasicProfile({
          artist_spotfy_id: artist.id,
          artist_spotfy_link: `${SPOTIFY_LINK}${artist.id}`,
          artist_name: artistName,
          artist_origin_fan: userId,
          artist_origin_platform: platformOrigin,
        });
        await api.log.insert('Inserted new artist', {
          ...userIpData,
          artistId: artist.id,
          timestamp: new Date(),
          type: 'Fan',
          details: `New artist - ${artistName} added from ${platformOrigin}}`,
        });
      }
    }
    const followedArtists = await api.db.artists.getArtistsBySpecificId(missingArtistIds, id);

    return followedArtists.concat(savedArtists);
  } else return savedArtists;
};

export const updateAppleArtistsByFlywheel = async (artists, userId, userIpData, originPlatform) => {
  if (!artists || !artists.length) return;
  const artistIds = artists?.map((artist) => artist.id) ?? [];
  const id = 'artist_aplmus_id';
  const presentArtists = await api.db.artists.getArtistsBySpecificId(artistIds, id);
  if (presentArtists?.length !== artists?.length && artists) {
    for (let index = 0; index < artists?.length; index++) {
      const artist = artists[index];
      const artistName = artist?.name || artist?.attributes?.name;
      if (!presentArtists.find((e) => e[id] === artist.id) && artistName) {
        try {
          const artistData = await api.db.artists.getFollowedArtistByName(artistName);
          if (artistData?.length) presentArtists.push(artistData[0]);
        } catch (error) {
          console.log(error, `${artistName} - Not found by name`);
        }
      }
    }
  }

  if (presentArtists?.length !== artistIds.length && artists) {
    const missingArtistIds = [];
    for (let index = 0; index < artists.length; index++) {
      const artist = artists[index];
      const artistName = artist?.name || artist?.attributes?.name;
      if (!presentArtists?.find((e) => e[id] === artist.id || e?.artist_name === artistName)) {
        missingArtistIds.push(artist.id);
        await api.db.artists.insertArtistBasicProfile({
          artist_spotfy_id: `${SPOTIFY_UNKNOWN}_${getTimeStampString()}${index}`,
          artist_aplmus_id: artist.id,
          artist_name: artistName,
          artist_genre: artist?.attributes?.genreNames
            ? artist.attributes.genreNames.join(',')
            : null,
          artist_origin_fan: userId,
          artist_origin_platform: originPlatform,
        });
        await api.log.insert('Inserted new artist', {
          ...userIpData,
          artistId: artist.id,
          timestamp: new Date(),
          type: 'Fan',
          details: `New artist - ${artistName} added from APPLE`,
        });
      }
    }
    const followedArtists = await api.db.artists.getArtistsBySpecificId(missingArtistIds, id);

    return followedArtists.concat(presentArtists);
  } else return presentArtists;
};
