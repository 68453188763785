import { Route, Routes } from 'react-router-dom';
import {
  AboutView,
  ToolsView,
  UserView,
  NotFoundView,
  FanFeedPage,
  FollowingPage,
  FavouritesPage,
  DiscoverPage,
  ConnectSocialsPage,
  ResyncSocialsPage,
  ProfilePage,
  AccountPage,
  LinkedSocialsPage,
  SubscriptionPage,
  ActivityPage,
  SettingsPage,
  ArtistProfilePage,
  MessagingPage,
} from '../pages';

/**
 * List of routes available only for authenticated users
 * @component PrivateRoutes
 */
const PrivateRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<FanFeedPage />} />
      <Route path="/following" element={<FollowingPage />} />
      <Route path="/profile" element={<ProfilePage />} />
      <Route path="/artist/:id" element={<ArtistProfilePage />} />
      <Route path="/account" element={<AccountPage />} />
      <Route path="/subscription" element={<SubscriptionPage />} />
      <Route path="/activity" element={<ActivityPage />} />
      <Route path="/settings" element={<SettingsPage />} />
      <Route path="/linked-socials" element={<LinkedSocialsPage />} />
      <Route path="/discover" element={<DiscoverPage />} />
      <Route path="/favorites" element={<FavouritesPage />} />
      <Route path="/connect" element={<ConnectSocialsPage />} />
      <Route path="/resync" element={<ResyncSocialsPage />} />
      <Route path="/message" element={<MessagingPage />} />
      <Route path="/about" element={<AboutView />} />
      <Route path="/tools/*" element={<ToolsView />} />
      <Route path="/user/*" element={<UserView />} />
    </Routes>
  );
};

export default PrivateRoutes;
