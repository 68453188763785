import { api } from '..';
import { LOG_IN, LOG_OUT } from '../../store/Actions';
import suprsend from '@suprsend/web-sdk';
import { getGlobalDispatch, getGlobalState } from '../../store/AppStore';

export const login = async (user, userIpData = {}) => {
  try {
    const platform = user.sub.includes('spotify')
      ? 'SPOTIFY'
      : user.sub.includes('apple')
      ? 'APPLE'
      : 'GOOGLE';
    const data = {
      fan_email: user.email,
      fan_name: user.name,
      temp_country: user.address || null,
      spotify_followed_artists_id: [],
      apple_followed_artists_id: [],
      fan_picture_link: user.picture || null,
      fan_source_platform: platform,
    };
    const userData = await api.db.user.profile.getUserData(data, userIpData);

    //
    if (userData && userData.fan_account_active) {
      if (!localStorage.getItem('logIn')) {
        await api.db.user.profile.updateProfileData(userData.rtsvrf_fan_uuid, {
          [`${platform.toLocaleLowerCase()}_login_timestamp`]: new Date(),
        });
        await api.log.insert('Log In', {
          ...userIpData,
          email: user.email,
          timestamp: new Date(),
          type: 'Fan',
          details: data.fan_source_platform,
        });
        suprsend.identify(userData.rtsvrf_fan_uuid);
        suprsend.user.set('name', userData.fan_name);
        suprsend.user.add_email(userData.fan_email);
        if (userData?.phone_number && userData?.dial_prefix) {
          suprsend.user.add_sms(userData.dial_prefix + userData.phone_number);
        }
      }
    } else {
      await api.log.insert(
        `Attempt to log into deactivated account - ${userData.fan_name ?? user.name}`,
        {
          ...userIpData,
          email: user.email,
          timestamp: new Date(),
          type: 'Fan',
          details: `User with email ${user.email} tried to log into deactivated account`,
        }
      );
      return { error: true, message: 'You do not have permission to access this app' };
    }
    //

    const dispatch = getGlobalDispatch();
    dispatch({ type: LOG_IN, payload: userData });
    return userData;
  } catch (error) {
    log.error(`LOG IN -`, error);
    return { error: true, message: error ?? error.message ?? 'Something went wrong, Try again!' };
  }
};

export const logoutWithRedirect = async () => {
  const state = getGlobalState();
  const userIpData = state.userIpData;
  const user = state.currentUser;

  await api.GA.sendEventData('LogOut', 'Click', 'LogOut');
  await api.log.insert('Log Out', {
    ...userIpData,
    email: user.email,
    timestamp: new Date(),
    type: 'Fan',
  });
  suprsend.reset();
  localStorage.clear();
  const dispatch = getGlobalDispatch();
  dispatch({ type: LOG_OUT });
};
