import React, { createContext, useReducer, useContext, useEffect } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { localStorageGet } from '../utils/localStorage';
import AppReducer from './AppReducer';

const INITIAL_APP_STATE = {
  darkMode: false, // Overridden by useMediaQuery('(prefers-color-scheme: dark)') in AppStore
  error: '',
  isAuthenticated: false,
  currentUser: undefined,
  userIpData: undefined,
  hideBottomBarPageList: [
    '/connect',
    '/connect/',
    '/resync',
    '/resync/',
    '/profile',
    '/account',
    '/linked-socials',
    '/subscription',
    '/activity',
    '/settings',
  ],
  hideProfileIcon: ['/connect', '/connect/', '/resync', '/resync/'],
  showTopBarPageList: ['/connect', '/resync', '/'],
};

let globalState;
let globalDispatch;

export const setGlobalState = (state) => {
  globalState = state;
};

export const getGlobalState = () => globalState;

const setGlobalDispatch = (dispatch) => {
  globalDispatch = dispatch;
};

export const getGlobalDispatch = () => globalDispatch;

/**
 * Instance of React Context for global AppStore
 */
const AppStoreContext = createContext([INITIAL_APP_STATE, () => null]);

/**
 * Main global Store as HOC with React Context API
 *
 * import AppStoreProvider from './store'
 * ...
 * <AppStoreProvider>
 *  <App/>
 * </AppStoreProvider>
 */
export const AppStoreProvider = ({ children }) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const previousDarkMode = Boolean(localStorageGet('darkMode'));
  // const tokenExists = Boolean(loadToken());

  const initialState = {
    ...INITIAL_APP_STATE,
    darkMode: previousDarkMode || prefersDarkMode,
    // isAuthenticated: tokenExists,
  };

  const [state, dispatch] = useReducer(AppReducer, initialState);

  // Set the global dispatch when the provider is initialized
  useEffect(() => {
    setGlobalState(state);
    setGlobalDispatch(dispatch);
  }, [dispatch, state]);

  return <AppStoreContext.Provider value={[state, dispatch]}>{children}</AppStoreContext.Provider>;
};

/**
 * Hook to use the AppStore in functional components
 */
export const useAppStore = () => useContext(AppStoreContext);

/**
 * HOC to inject the ApStore to class component
 */
export const withAppStore = (Component) => (props) => {
  return <Component {...props} store={useAppStore()} />;
};
