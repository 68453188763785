import { supabase } from '../SupabaseClient';
import { api } from '../..';
import { shuffleArray } from '../../../utils/helpers';
import * as flywheel from './flywheel';

export { flywheel };

export const getArtistsByRtsvrfId = async (artistIds) => {
  if (!artistIds) return;
  const results = await supabase
    .from('tbl_locked_artist_ids_stub_v2')
    .select()
    .in('rtsvrf_artist_id', artistIds);
  return results.data ?? [];
};

export const getArtistImages = async (artists) => {
  const results = await supabase
    .from('tbl_locked_artist_pics')
    .select()
    .in('rtsvrf_artist_id', artists);
  return results.data ?? [];
};

export const getArtistImage = async (artistId) => {
  const results = await supabase
    .from('tbl_locked_artist_pics')
    .select()
    .eq('rtsvrf_artist_id', artistId)
    .single();
  return results.data;
};

export const getArtistsByAppleAndSpotifyId = async (spotifyIds, appleIds) => {
  if (!spotifyIds && !appleIds) return;
  const results = await supabase
    .from('tbl_locked_artist_ids_stub_v2')
    .select()
    .or(
      `artist_spotfy_id.in.(${spotifyIds.join(',')}), artist_aplmus_id.in.(${appleIds.join(',')})`
    )
    .order('artist_name', { ascending: true });
  return results.data;
};

export const getArtistGenreByAppleAndSpotifyId = async (spotifyIds, appleIds) => {
  if (!spotifyIds && !appleIds) return;
  const results = await supabase
    .from('tbl_locked_artist_ids_stub_v2')
    .select('artist_genre', { distinct: true })
    .or(
      `artist_spotfy_id.in.(${spotifyIds.join(',')}), artist_aplmus_id.in.(${appleIds.join(',')})`
    );
  return results.data;
};

export const getArtistsByNameSearch = async (searchString) => {
  if (!searchString) return;

  const results = await supabase
    .from('tbl_locked_artist_ids_stub_v2')
    .select()
    .ilike('artist_name', `%${searchString}%`);
  return results.data ?? [];
};

export const searchArtistsByGenre = async (
  genreList,
  spotifyArtists,
  appleArtists,
  includeImages,
  slice = 5
) => {
  if (!genreList) return;
  const results = await supabase
    .from('tbl_locked_artist_ids_stub_v2')
    .select()
    .in('artist_genre', genreList)
    .not('artist_spotfy_id', 'in', `(${spotifyArtists.join(',')})`)
    .not('artist_aplmus_id', 'in', `(${appleArtists.join(',')})`);
  const artists = shuffleArray(results.data ?? [])?.slice(0, slice);
  const artistImages = includeImages
    ? await api.db.artists.getArtistImages(artists.map((e) => e.rtsvrf_artist_id))
    : [];
  return { artists, artistImages };
};

export const searchAndGetArtistData = async (searchString) => {
  if (!searchString) return;

  const artists = await getArtistsByNameSearch(searchString);
  const artistImages = artists?.length
    ? await api.db.artists.getArtistImages(artists.map((e) => e.rtsvrf_artist_id))
    : [];
  return { artists, artistImages };
};

export const searchAndGetFollowedArtistData = async (spotifyIds, appleIds, searchString) => {
  if (!spotifyIds && !appleIds) return;
  const results = await supabase
    .from('tbl_locked_artist_ids_stub_v2')
    .select()
    .or(
      `artist_spotfy_id.in.(${spotifyIds.join(',')}), artist_aplmus_id.in.(${appleIds.join(',')})`
    )
    .ilike('artist_name', `%${searchString}%`)
    .order('artist_name', { ascending: true });
  return results.data;
};

export const getPopularArtists = async (includeImages, slice = 5) => {
  const results = await supabase
    .from('tbl_locked_artist_ids_stub_v2')
    .select()
    .eq('demo_top100', true);

  const artists = shuffleArray(results.data ?? [])?.slice(0, slice);
  const artistImages = includeImages
    ? await api.db.artists.getArtistImages(artists.map((e) => e.rtsvrf_artist_id))
    : [];
  return { artists, artistImages };
};

export const getRecentlyFollowedArtists = async (includeImages, slice = 5) => {
  const currentDate = new Date();
  const fromValue = new Date(currentDate);
  fromValue.setDate(currentDate.getDate() - 21);
  const toValue = new Date(currentDate);
  toValue.setDate(currentDate.getDate() - 7);

  const results = await supabase
    .from('tbl_locked_artist_ids_stub_v2')
    .select()
    .filter('created_at', 'gte', fromValue.toISOString())
    .filter('created_at', 'lt', toValue.toISOString());

  const artists = shuffleArray(results.data ?? [])?.slice(0, slice);
  const artistImages = includeImages
    ? await api.db.artists.getArtistImages(artists.map((e) => e.rtsvrf_artist_id))
    : [];
  return { artists, artistImages };
};

export const getArtistsBySpecificId = async (artistIds, id) => {
  if (!artistIds || !id) return;
  const results = await supabase
    .from('tbl_locked_artist_ids_stub_v2')
    .select()
    .in(id, artistIds)
    .order('artist_name', { ascending: true });

  return results.data;
};

export const getFollowedArtistByName = async (artistName) => {
  if (!artistName) return;
  const results = await supabase
    .from('tbl_locked_artist_ids_stub_v2')
    .select()
    .eq('artist_name', artistName);
  return results.data;
};

export const insertArtistBasicProfile = async (data) => {
  if (!data) return;
  let results = await supabase.from('tbl_locked_artist_ids_stub_v2').insert(data);
  return results;
};

export const getArtistProfile = async (artistId, includeImage) => {
  if (!artistId) return {};

  const result = await supabase
    .from('tbl_locked_artist_ids_stub_v2')
    .select()
    .eq('rtsvrf_artist_id', artistId)
    .single();
  const artist = result.data ?? null;
  const artistImage = artist && includeImage ? await getArtistImage(artist.rtsvrf_artist_id) : null;
  return { artist, artistImage: artistImage ?? null };
};

export const getArtistNewsByGenre = async (artistGenre) => {
  const result = await supabase
    .from('tbl_staged_genre_news')
    .select()
    .eq('news_genre', artistGenre)
    .single();
  return result.data ?? null;
};

export const getArtistProfilePreferences = async (artistId) => {
  if (!artistId) return;
  const { data, error } = await supabase
    .from('tbl_staged_artist_profile_preferences')
    .select()
    .eq('rtsvrf_artist_id', artistId)
    .single();

  return (
    data ?? {
      rtsvrf_artist_id: artistId,
      artist_image: true,
      link_icons_list: [],
      artist_news: true,
      social_stream_embed1: null,
      social_stream_embed2: null,
      merch_offer: null,
      tickets_offer: null,
    }
  );
};

export const getArtistProfileV2Preferences = async (artistId) => {
  if (!artistId) return;
  const { data, error } = await supabase
    .from('tbl_locked_artist_profile_v2_preferences')
    .select()
    .eq('rtsvrf_artist_id', artistId)
    .single();

  return (
    data ?? {
      rtsvrf_artist_id: artistId,
      artist_image: true,
      link_icons_list: [],
      artist_news: true,
      social_stream_embed1: null,
      social_stream_embed2: null,
      merch_offer: null,
      tickets_offer: null,
    }
  );
};

export const getArtistEditableFields = async (artistId) => {
  if (!artistId) return;
  const results = await supabase
    .from('tbl_locked_artist_editable_fields')
    .select()
    .eq('rtsvrf_artist_id', artistId)
    .single();
  return results.data;
};

export const getPlatformLinks = async () => {
  const result = await supabase.from('tbl_locked_platform_list').select();
  return result.data;
};
