import { supabase } from '../SupabaseClient';
import { api } from '../..';

export const getCountryNames = async () => {
  const results = await supabase.from('tbl_locked_countries').select();
  return results?.data;
};

export const getCountryPhoneNumberCodes = async () => {
  const results = await supabase.from('tbl_locked_phone_codes').select();
  return results?.data;
};
