import { supabase } from '../SupabaseClient';
import { api } from '../..';
import { getGlobalState } from '../../../store/AppStore';
import { SPOTIFY_UNKNOWN, userNotificationMapping } from '../../../utils/constants';
import { getTimeStampString } from '../../../utils/helpers';

export const syncSpotifyFollowedArtists = async (artists) => {
  const state = getGlobalState();
  if (!state?.currentUser) return [];
  const user = state.currentUser;
  const userIpData = state.userIpData;

  await api.log.insert('Connect Spotify', {
    ...userIpData,
    email: user?.fan_email,
    timestamp: new Date(),
    type: 'Fan',
    details: `Connected spotify with ${artists?.length} followed artists`,
  });
  const artistsData = await api.db.artists.flywheel.updateSpotifyArtistsByFlywheel(
    artists,
    user.rtsvrf_fan_uuid,
    userIpData,
    'SPOTIFY'
  );

  let followedArtistList = [...(user.spotify_followed_artists_id ?? [])];

  let artistsSubscriptionList = [];
  artistsData?.forEach((e) => {
    if (!followedArtistList.includes(e.artist_spotfy_id)) {
      followedArtistList.push(e.artist_spotfy_id);

      artistsSubscriptionList.push({
        list_id: e.rtsvrf_artist_id,
        list_name: `${e.artist_name}_all_followers`,
        list_description: `${e.artist_name}_all_followers`,
      });
    }
  });

  await api.db.user.profile.updateProfileData(user.rtsvrf_fan_uuid, {
    spotify_followed_artists_id: followedArtistList,
    ...{ spotify_stream_connection_timestamp: new Date() },
  });
  if (!user?.spotify_followed_artists_id?.length) {
    await api.suprsend.notifyUser(user.rtsvrf_fan_uuid, {
      details: { UserName: user?.fan_name },
      type: userNotificationMapping.newFollower,
      name: `${userNotificationMapping.newFollower}${user?.fan_name || user.fan_email}`,
    });
  }

  await api.suprsend.subscribeUserToMultipleArtists(user.rtsvrf_fan_uuid, artistsSubscriptionList);
  return followedArtistList;
};

export const syncAndUpdateAppleArtistData = async (artists, userId, userIpData, originPlatform) => {
  if (!artists || !artists.length) return;
  const artistIds = artists?.map((artist) => artist.id) ?? [];
  const id = 'artist_aplmus_id';
  const savedArtists = await api.db.artists.getArtistsBySpecificId(artistIds, id, artists);

  if (savedArtists?.length !== artistIds.length && artists) {
    const missingArtistIds = [];
    for (let index = 0; index < artists.length; index++) {
      const artist = artists[index];
      const artistName = artist?.name || artist?.attributes?.name;
      if (!savedArtists?.find((e) => e[id] === artist.id || e?.artist_name === artistName)) {
        missingArtistIds.push(artist.id);
        const insertArtist = await api.db.artists.insertArtistBasicProfile({
          artist_spotfy_id: `${SPOTIFY_UNKNOWN}_${getTimeStampString()}${index}`,
          artist_aplmus_id: artist.id,
          artist_name: artistName,
          artist_genre: artist?.attributes?.genreNames
            ? artist.attributes.genreNames.join(',')
            : null,
          artist_origin_fan: userId,
          artist_origin_platform: originPlatform,
        });
        await api.log.insert('Inserted new artist', {
          ...userIpData,
          artistId: artist.id,
          timestamp: new Date(),
          type: 'Fan',
          details: `New artist - ${artistName} added from APPLE`,
        });
      }
    }
    const followedArtists = await api.db.artists.getArtistsBySpecificId(missingArtistIds, id);

    return followedArtists.concat(savedArtists);
  } else return savedArtists;
};

export const saveAppleFollowedArtist = async (artists) => {
  const state = getGlobalState();
  if (!state?.currentUser) return [];
  const user = state.currentUser;
  const userIpData = state.userIpData;

  if (artists?.length) {
    let artistsData = await api.db.artists.flywheel.updateAppleArtistsByFlywheel(
      artists,
      user.rtsvrf_fan_uuid,
      userIpData,
      'APPLE'
    );

    let artistsSubscriptionList = [];
    let followedAppleArtistList = [...(user.apple_followed_artists_id ?? [])];
    let followedSpotifyArtistList = [...(user.spotify_followed_artists_id ?? [])];

    artistsData?.forEach((e) => {
      if (
        !followedAppleArtistList.includes(e.artist_aplmus_id) &&
        !followedSpotifyArtistList.includes(e.artist_spotfy_id)
      ) {
        if (e.artist_aplmus_id) {
          followedAppleArtistList.push(e.artist_aplmus_id);
        } else {
          followedSpotifyArtistList.push(e.artist_spotfy_id);
        }
        artistsSubscriptionList.push({
          list_id: e.rtsvrf_artist_id,
          list_name: `${e.artist_name}_all_followers`,
          list_description: `${e.artist_name}_all_followers`,
        });
      }
    });

    if (!user?.apple_followed_artists_id?.length) {
      await api.suprsend.notifyUser(user.rtsvrf_fan_uuid, {
        details: { UserName: user?.fan_name },
        type: userNotificationMapping.newFollower,
        name: `${userNotificationMapping.newFollower}${user?.fan_name || user.fan_email}`,
      });
    }

    const result = await api.db.user.profile.updateProfileData(user.rtsvrf_fan_uuid, {
      apple_followed_artists_id: [...followedAppleArtistList],
      apple_stream_connection_timestamp: new Date(),
      ...(followedSpotifyArtistList?.length
        ? {
            spotify_followed_artists_id: [...followedSpotifyArtistList],
          }
        : {}),
    });
    if (artistsSubscriptionList?.length)
      await api.suprsend.subscribeUserToMultipleArtists(
        user.rtsvrf_fan_uuid,
        artistsSubscriptionList
      );
  }
  await api.log.insert('Connect Apple', {
    ...userIpData,
    email: user?.fan_email,
    timestamp: new Date(),
    type: 'Fan',
    details: `Connected Apple with ${artists?.length} followed artists`,
  });
};

export const getUserAppleJwtToken = async () => {
  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${process.env.REACT_APP_INTERNAL_SERVER}/get-jwt-token`, options);
  const result = await response.json();
  return result;
};

export const followArtistsOnSpotifySync = async (selectedArtists) => {
  const state = getGlobalState();
  const user = state.currentUser;
  const userIpData = state.userIpData;
  await api.GA.sendEventData('Sync Spotify', 'click', 'Sync Spotify');

  if (!selectedArtists?.length) {
    await api.log.insert('Sync Spotify', {
      ...userIpData,
      email: user?.fan_email,
      timestamp: new Date(),
      type: 'Fan',
      details: `Synced Spotify with 0 followed artists`,
    });
    const result = await api.db.user.profile.updateProfileData(user.rtsvrf_fan_uuid, {
      artists_last_sync_timestamp: new Date(),
    });
    return { success: result.data, error: result.error, message: result.error?.message };
  }

  console.log('user with new artists', user);
  console.log('followArtistsOnSpotify', selectedArtists);
  const artistsData = await api.db.artists.flywheel.updateSpotifyArtistsByFlywheel(
    selectedArtists,
    user.rtsvrf_fan_uuid,
    userIpData,
    'FLYWHEEL'
  );
  console.log('artistsData', artistsData);

  let artistIds = [];
  let artistsSubscriptionList = [];
  artistsData?.forEach((e) => {
    artistIds.push(e.artist_spotfy_id);
    artistsSubscriptionList.push({
      list_id: e.rtsvrf_artist_id,
      list_name: `${e.artist_name}_all_followers`,
      list_description: `${e.artist_name}_all_followers`,
    });
  });

  if (artistIds.length) {
    const result = await api.db.user.profile.updateProfileData(user.rtsvrf_fan_uuid, {
      spotify_followed_artists_id: [...artistIds, ...user.spotify_followed_artists_id],
      artists_last_sync_timestamp: new Date(),
    });
    if (result.error) {
      return { error: true, message: result.error.message ?? 'Something went wrong!' };
    } else {
      await api.suprsend.subscribeUserToMultipleArtists(
        user.rtsvrf_fan_uuid,
        artistsSubscriptionList
      );
      await api.log.insert('Sync Spotify', {
        ...userIpData,
        email: user?.fan_email,
        timestamp: new Date(),
        type: 'Fan',
        details: `Synced Apple with ${artistsSubscriptionList?.length} followed artists`,
      });

      return { success: true };
    }
  } else {
    return { error: true, message: 'Not able to find selected artists!' };
  }
};

export const followArtistsOnAppleSync = async (selectedArtists) => {
  await api.GA.sendEventData('Sync Apple', 'click', 'Sync Apple');
  const state = getGlobalState();
  const user = state.currentUser;
  const userIpData = state.userIpData;

  if (!selectedArtists?.length) {
    await api.log.insert('Sync Apple', {
      ...userIpData,
      email: user?.fan_email,
      timestamp: new Date(),
      type: 'Fan',
      details: `Synced Apple with 0 followed artists`,
    });
    const result = await api.db.user.profile.updateProfileData(user.rtsvrf_fan_uuid, {
      artists_last_sync_timestamp: new Date(),
    });
    return { success: result.data, error: result.error, message: result.error?.message };
  }

  const artistsData = await api.db.artists.flywheel.updateAppleArtistsByFlywheel(
    selectedArtists,
    user.rtsvrf_fan_uuid,
    userIpData,
    'FLYWHEEL'
  );
  console.log('artistsData', artistsData);
  let artistsSubscriptionList = [];
  let followedAppleArtistList = [];
  let followedSpotifyArtistList = [];
  let followedRtsvrfArtistList = [];
  artistsData?.forEach((e) => {
    if (e.artist_aplmus_id) {
      followedAppleArtistList.push(e.artist_aplmus_id);
    } else {
      followedSpotifyArtistList.push(e.artist_spotfy_id);
    }
    followedRtsvrfArtistList.push(e.rtsvrf_artist_id);
    artistsSubscriptionList.push({
      list_id: e.rtsvrf_artist_id,
      list_name: `${e.artist_name}_all_followers`,
      list_description: `${e.artist_name}_all_followers`,
    });
  });

  const result = await api.db.user.profile.updateProfileData(user.rtsvrf_fan_uuid, {
    apple_followed_artists_id: [
      ...(user?.apple_followed_artists_id ?? []),
      ...followedAppleArtistList,
    ],
    artists_last_sync_timestamp: new Date(),
    ...(followedSpotifyArtistList?.length
      ? {
          spotify_followed_artists_id: [
            ...(user?.spotify_followed_artists_id ?? []),
            ...followedSpotifyArtistList,
          ],
        }
      : {}),
  });
  if (result.error) {
    return { error: true, message: result.error.message ?? 'Something went wrong!' };
  } else {
    await api.suprsend.subscribeUserToMultipleArtists(
      user.rtsvrf_fan_uuid,
      artistsSubscriptionList
    );

    await api.log.insert('Sync Apple', {
      ...userIpData,
      email: user?.fan_email,
      timestamp: new Date(),
      type: 'Fan',
      details: `Synced Apple with ${artistsSubscriptionList?.length} followed artists`,
    });
    return { success: true };
  }
};
