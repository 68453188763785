export const NEWS_LINK = 'https://news.rtsvrf.com/news';
export const PROFILE_LINK = 'https://profile.rtsvrf.com/artist';
export const SPOTIFY_LINK = 'https://open.spotify.com/artist/';
export const CONNECT_SPOTIFY = 'Connect Spotify';
export const SYNC_SPOTIFY = 'Sync Spotify';
export const SPOTIFY_UNKNOWN = 'SPOTIFY_UNKNOWN';
export const HomePagePreference = {
  FollowedArtists: 'FollowedArtists',
  CustomNewsFeed: 'CustomNewsFeed',
  AVInbox: 'AVInbox',
};

export const ProfilePagePreference = {
  Details: 'Details',
  ArtistRelations: 'ArtistRelations',
  Settings: 'Settings',
  Subscription: 'Subscription',
  Activity: 'Activity',
};

export const DataSharingPreference = {
  SHARE_ALL_DATA_WITH_FOLLOWED: 'SHARE_ALL_DATA_WITH_FOLLOWED',
  COMMS_ONLY_WITH_FOLLOWED: 'COMMS_ONLY_WITH_FOLLOWED',
  DEMOG_ONLY_WITH_FOLLOWED: 'DEMOG_ONLY_WITH_FOLLOWED',
  SHARE_NO_DATA_WITH_FOLLOWED: 'SHARE_NO_DATA_WITH_FOLLOWED',
};

export const customArtistActivities = {
  NewMusic: 'new_music',
  ToursAndTickets: 'tours_and_tickets',
  Merch: 'merch',
  SpecialOffers: 'special_offers',
};

export const customArtistSources = {
  WebNews: 'web_news',
  SocialMedia: 'social_media',
  ArtistDirectOnly: 'artist_direct_only',
};

export const customMessageChannels = {
  push: 'push',
  sms: 'sms',
  email: 'email',
  web3: 'web3',
};

export const modalCustomStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
    padding: '12px',
    maxWidth: '400px',
    width: '100%',
    backgroundColor: '#FFFFFF',
  },
};

export const userNotificationMapping = {
  welcome: 'Welcome',
  newFollower: 'NewFollower',
};

export const BOTTOMBAR_ITEMS = [
  {
    title: 'Home',
    path: '/',
    icon: 'home',
  },
  {
    title: 'Following',
    path: '/following',
    icon: 'favourite',
  },
  {
    title: 'Discover',
    path: '/discover',
    icon: 'search',
  },
  {
    title: 'Messages',
    path: '/message',
    icon: 'message',
  },
];

export const fieldMappings = {
  BNDSIT: 'artist_bandsit_id',
  PATREN: 'artist_patren_id',
  MRCHBR: 'artist_mrchbr_id',
  DISCRD: 'artist_discrd_link',
};

export const platformMappings = {
  THREDS: 'artist_threads_id',
  SNAPCH: 'artist_snapchat_id',
  MASTDN: 'artist_mastodon_id',
  BLUSKY: 'artist_bluesky_id',
  DISCRD: 'artist_discord_id',
  SIGNAL: 'artist_signal_id',
  WHTSPP: 'artist_whatsapp_id',
  TELGRM: 'artist_telegram_id',
};

export const HUBSPOT_FORM = 'https://share.hsforms.com/1tMnLZSTLTiS892R0hvfkgwdyhum';
