import { BrowserRouter } from 'react-router-dom';
import { AppStoreProvider } from './store';
import { AppThemeProvider } from './theme';
import { AppSnackBarProvider } from './components/AppSnackBar';
import Routes from './routes';
import { Auth0Provider } from '@auth0/auth0-react';
import { createBrowserHistory } from 'history';
import Layout from './layout';
import { ErrorBoundary } from './components';
import IdleTimer from './components/IdleTimer';
import { getConfig } from './utils/config';
import { WagmiConfig, createConfig, configureChains, mainnet } from 'wagmi';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import './App.css';

/**
 * Root Application Component
 * @component App
 */
const App = () => {
  const config = getConfig();
  const onRedirectCallback = (appState) => {
    createBrowserHistory().push(
      appState && appState.returnTo ? appState.returnTo : window.location.pathname
    );
  };
  const providerConfig = {
    domain: config.domain,
    clientId: config.clientId,
    onRedirectCallback,
    authorizationParams: {
      redirect_uri: window.location.origin,
      ...(config.audience ? { audience: config.audience } : null),
    },
  };

  const { chains, publicClient, webSocketPublicClient } = configureChains(
    [mainnet],
    [alchemyProvider({ apiKey: process.env.REACT_APP_ALCHEMY_KEY }), publicProvider()]
  );

  // Set up wagmi config
  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: [
      new WalletConnectConnector({
        chains,
        options: {
          projectId: process.env.REACT_APP_WALLETCONNECT_PROJECT_ID,
        },
      }),
    ],
    publicClient,
    webSocketPublicClient,
  });

  return (
    <ErrorBoundary name="App">
      <AppStoreProvider>
        <IdleTimer />
        <AppThemeProvider>
          <AppSnackBarProvider>
            <Auth0Provider {...providerConfig}>
              <WagmiConfig config={wagmiConfig}>
                <BrowserRouter>
                  <Layout>
                    <Routes />
                  </Layout>
                </BrowserRouter>
              </WagmiConfig>
            </Auth0Provider>
          </AppSnackBarProvider>
        </AppThemeProvider>
      </AppStoreProvider>
    </ErrorBoundary>
  );
};

export default App;
