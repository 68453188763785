import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import AuthRoutes from '../pages/Auth';
import { AboutView, NotFoundView, OnboardingPage, ResyncSocialsPage, WelcomePage } from '../pages';
import LoginEmailView from '../pages/Auth/Login/Email';

/**
 * List of routes available only for anonymous users
 * @component PublicRoutes
 */
const PublicRoutes = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const onboardingParam = queryParams.get('onboarding');

  // Decide which page to load based on the onboardingParam
  const getPageElement = () => {
    if (onboardingParam === 'short') {
      return <WelcomePage />;
    } else {
      return <OnboardingPage />;
    }
  };

  return (
    <Routes>
      <Route path="/" element={getPageElement()} />
      <Route path="welcome" element={<WelcomePage />} />
      <Route path="/resync" element={<ResyncSocialsPage />} />
      <Route path="auth/*" element={<AuthRoutes />} />
      <Route path="about" element={<AboutView />} />,
    </Routes>
  );
};

export default PublicRoutes;
