import { supabase } from '../SupabaseClient';
import { api } from '../..';
import { getGlobalState } from '../../../store/AppStore';
import { SPOTIFY_UNKNOWN } from '../../../utils/constants';

export const getFavouriteArtists = async (user) => {
  const favArtistIds = [];
  if (user['favourite_1']) {
    favArtistIds.push(user['favourite_1']);
  }
  if (user['favourite_2']) {
    favArtistIds.push(user['favourite_2']);
  }
  if (user['favourite_3']) {
    favArtistIds.push(user['favourite_3']);
  }
  if (user['favourite_4']) {
    favArtistIds.push(user['favourite_4']);
  }
  if (user['favourite_5']) {
    favArtistIds.push(user['favourite_5']);
  }

  const artists = await api.db.artists.getArtistsByRtsvrfId(favArtistIds);
  const artistImages = await api.db.artists.getArtistImages(favArtistIds);
  return { artists, artistImages, order: favArtistIds };
};

export const getFollowedArtists = async (user) => {
  if (!user) return [];

  const artists = await api.db.artists.getArtistsByAppleAndSpotifyId(
    user?.spotify_followed_artists_id ?? [],
    user?.apple_followed_artists_id ?? []
  );
  const artistImages = artists?.length
    ? await api.db.artists.getArtistImages(artists.map((e) => e.rtsvrf_artist_id))
    : [];
  return { artists, artistImages };
};

export const getSuggestedArtists = async (user) => {
  if (!user) return [];

  const favArtistIds = [];
  if (user['favourite_1']) {
    favArtistIds.push(user['favourite_1']);
  }
  if (user['favourite_2']) {
    favArtistIds.push(user['favourite_2']);
  }
  if (user['favourite_3']) {
    favArtistIds.push(user['favourite_3']);
  }
  if (user['favourite_4']) {
    favArtistIds.push(user['favourite_4']);
  }
  if (user['favourite_5']) {
    favArtistIds.push(user['favourite_5']);
  }
  let genreList = [];

  const list = await api.db.artists.getArtistsByRtsvrfId(favArtistIds);
  genreList = list.map((e) => e.artist_genre).filter((e) => e);
  if (!favArtistIds?.length || !genreList?.length) {
    const list = await api.db.artists.getArtistGenreByAppleAndSpotifyId(
      user?.spotify_followed_artists_id ?? [],
      user?.apple_followed_artists_id ?? []
    );
    genreList = list.map((e) => e.artist_genre).filter((e) => e);
  }

  const result = await api.db.artists.searchArtistsByGenre(
    genreList,
    user?.spotify_followed_artists_id,
    user?.apple_followed_artists_id,
    true
  );
  return result;
};

export const searchFollowedArtists = async (searchString) => {
  const state = getGlobalState();
  if (!state?.currentUser) return [];
  const user = state.currentUser;

  const artists = await api.db.artists.searchAndGetFollowedArtistData(
    user?.spotify_followed_artists_id ?? [],
    user?.apple_followed_artists_id ?? [],
    searchString
  );
  const artistImages = artists?.length
    ? await api.db.artists.getArtistImages(artists.map((e) => e.rtsvrf_artist_id))
    : [];
  return { artists, artistImages };
};

export const addFavouriteArtist = async (field, artist) => {
  if (!artist || !field) return { error: true, message: 'Artist not found' };
  const state = getGlobalState();
  if (!state?.currentUser) return { error: true, message: 'User not found' };
  const user = state.currentUser;
  const userIpData = state.userIpData ?? {};

  await api.GA.sendEventData('Add favourite artist', 'click', 'Add favourite artist');
  await api.log.insert(`${field} artist added - ${user?.fan_name}`, {
    ...userIpData,
    email: user?.fan_email,
    timestamp: new Date(),
    type: 'Fan',
    details: `${artist.artist_name} has been added as ${field} artist`,
  });
  const result = await api.db.user.profile.updateProfileData(user.rtsvrf_fan_uuid, {
    [field]: artist.rtsvrf_artist_id,
    [field + '_timestamp']: new Date(),
  });
  if (result.error) {
    return { error: true, message: result.error.message };
  } else {
    if (field === 'favourite_1') {
      await api.db.user.subscription.subscribeMostFavouriteArtist(user.rtsvrf_fan_uuid, artist);
    } else {
      await api.db.user.subscription.subscribeFavouriteArtist(user.rtsvrf_fan_uuid, artist);
    }
    return { success: true };
  }
};

export const followArtist = async (selectedArtist) => {
  if (!selectedArtist) return { error: true, message: 'Artist not found' };
  const state = getGlobalState();
  if (!state?.currentUser) return { error: true, message: 'User not found' };
  const user = state.currentUser;
  const userIpData = state.userIpData ?? {};

  await api.GA.sendEventData('Follow artist', 'click', 'Follow artist');
  await api.log.insert(`Artist followed - ${user?.fan_name}`, {
    ...userIpData,
    email: user?.fan_email,
    timestamp: new Date(),
    type: 'Fan',
    details: `${selectedArtist.artist_name} has been followed`,
  });

  let result = null;
  if (
    selectedArtist.artist_spotfy_id &&
    !selectedArtist.artist_spotfy_id?.includes(SPOTIFY_UNKNOWN)
  ) {
    result = await api.db.user.profile.updateProfileData(user.rtsvrf_fan_uuid, {
      spotify_followed_artists_id: [
        selectedArtist.artist_spotfy_id,
        ...(user.spotify_followed_artists_id ?? []),
      ],
    });
  } else if (selectedArtist.artist_aplmus_id) {
    result = await api.db.user.profile.updateProfileData(user.rtsvrf_fan_uuid, {
      apple_followed_artists_id: [
        selectedArtist.artist_aplmus_id,
        ...(user.apple_followed_artists_id ?? []),
      ],
    });
  } else {
    return { error: true, message: 'Artist does not have sufficient information to be followed' };
  }
  if (result?.error) {
    //error
    return { error: true, message: result.error.message };
  }

  result = await api.db.user.subscription.subscribeToFollowedArtist(
    user.rtsvrf_fan_uuid,
    selectedArtist
  );
  if (result.error) {
    return {
      error: true,
      message: result.message ?? 'An error occured while subscribing artist for messaging',
    };
  }
  return { success: true };
};

export const unfollowArtist = async (selectedArtist) => {
  if (!selectedArtist) return { error: true, message: 'Artist not found' };
  const state = getGlobalState();
  if (!state?.currentUser) return { error: true, message: 'User not found' };
  const user = state.currentUser;
  const userIpData = state.userIpData ?? {};

  await api.GA.sendEventData('Unfollow artist', 'click', 'Unfollow artist');

  let result = null;
  if (
    selectedArtist.artist_spotfy_id &&
    user?.spotify_followed_artists_id?.includes(selectedArtist.artist_spotfy_id)
  ) {
    result = await unfollowArtistOnSpotify(selectedArtist, user);
  }
  if (
    selectedArtist.artist_aplmus_id &&
    user?.apple_followed_artists_id?.includes(selectedArtist.artist_aplmus_id)
  ) {
    result = await unfollowArtistOnApple(selectedArtist, user);
  }
  if (result?.error) {
    return { error: true, message: 'Something went wrong' };
  }

  const subscriptionResult = await api.suprsend.unsubscribeUserFromArtist(
    user.rtsvrf_fan_uuid,
    selectedArtist.rtsvrf_artist_id
  );
  if (subscriptionResult?.error) {
    return {
      error: true,
      message: 'An error occured while unsubscribing artist for messaging',
    };
  }
  await api.log.insert('Unfollow Artist', {
    ...userIpData,
    email: user?.fan_email,
    timestamp: new Date(),
    type: 'Fan',
    details: `Unfollowed artist - ${selectedArtist.artist_name} and unsubscribed to ${selectedArtist.artist_name}_all_followers list for messaging`,
  });
  return { success: true };
};
const unfollowArtistOnSpotify = async (artist, user) => {
  const result = await api.db.user.profile.updateProfileData(user.rtsvrf_fan_uuid, {
    spotify_followed_artists_id: user.spotify_followed_artists_id.filter(
      (e) => e !== artist.artist_spotfy_id
    ),
  });
  return result;
};

const unfollowArtistOnApple = async (artist, user) => {
  const result = await api.db.user.profile.updateProfileData(user.rtsvrf_fan_uuid, {
    apple_followed_artists_id: user.apple_followed_artists_id.filter(
      (e) => e !== artist.artist_aplmus_id
    ),
  });
  return result;
};
