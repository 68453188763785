import { supabase } from '../SupabaseClient';
import { api } from '../..';

export const getLogHistory = async (email, currentPage = 1, limit = 5) => {
  if (!email) return;
  let results = await supabase
    .from('tbl_locked_fans_logs_history')
    .select()
    .order('timestamp', { ascending: false })
    .eq('email', email)
    .range((currentPage - 1) * limit, (currentPage - 1) * limit + (limit - 1));
  return results;
};

export const getTotalLogHistory = async (email) => {
  if (!email) return;
  let results = await supabase
    .from('tbl_locked_fans_logs_history')
    .select('*', { count: 'exact' })
    .eq('email', email);

  return results.count;
};
