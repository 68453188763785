import { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  TextField,
  Card,
  CardHeader,
  CardContent,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  LinearProgress,
  Autocomplete,
} from '@mui/material';
import { useAppStore } from '../../../store';
import { AppButton, AppIconButton, AppTextField } from '../../../components';
import { AppForm, AppAlert } from '../../../components/forms';
import { useAppForm, SHARED_CONTROL_PROPS, eventPreventDefault } from '../../../utils/form';
import { api } from '../../../api';
import { SIGN_UP } from '../../../store/Actions';

const VALIDATE_FORM_SIGNUP = {
  email: {
    email: true,
    presence: true,
  },
  phone: {
    type: 'string',
    format: {
      pattern: '^$|[- .+()0-9]+', // Note: We have to allow empty in the pattern
      message: 'should contain numbers',
    },
  },
  firstName: {
    type: 'string',
    presence: { allowEmpty: false },
    format: {
      pattern: '^[A-Za-z ]+$', // Note: Allow only alphabets and space
      message: 'should contain only alphabets',
    },
  },
  lastName: {
    type: 'string',
    presence: { allowEmpty: false },
    format: {
      pattern: '^[A-Za-z ]+$', // Note: Allow only alphabets and space
      message: 'should contain only alphabets',
    },
  },
  password: {
    presence: true,
    length: {
      minimum: 8,
      maximum: 32,
      message: 'must be between 8 and 32 characters',
    },
  },
};

const VALIDATE_EXTENSION = {
  confirmPassword: {
    equality: 'password',
  },
};

/**
 * Renders "Signup" view
 * url: /auth/signup
 */
const SignupView = () => {
  const navigate = useNavigate();
  const [, dispatch] = useAppStore();
  const [validationSchema, setValidationSchema] = useState({
    ...VALIDATE_FORM_SIGNUP,
    ...VALIDATE_EXTENSION,
  });
  const [formState, , /* setFormState */ onFieldChange, fieldGetError, fieldHasError] = useAppForm({
    validationSchema: validationSchema, // the state value, so could be changed in time
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      password: '',
      confirmPassword: '',
    },
  });
  const [showPassword, setShowPassword] = useState(false);
  const [agree, setAgree] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [value, setValue] = useState({ code: 'AF', label: 'Afghanistan', phone: '93' });
  const [inputValue, setInputValue] = useState('');
  const values = formState.values; // Typed alias to formState.values as the "Source of Truth"

  useEffect(() => {
    // Component Mount
    let componentMounted = true;

    async function fetchData() {
      //TODO: Call any Async API here
      if (!componentMounted) return; // Component was unmounted during the API call
      //TODO: Verify API call here

      setLoading(false); // Reset "Loading..." indicator
    }
    fetchData(); // Call API asynchronously

    return () => {
      // Component Un-mount
      componentMounted = false;
    };
  }, []);

  useEffect(() => {
    // Update Validation Schema when Show/Hide password changed
    let newSchema;
    if (showPassword) {
      newSchema = VALIDATE_FORM_SIGNUP; // Validation without .confirmPassword
    } else {
      newSchema = { ...VALIDATE_FORM_SIGNUP, ...VALIDATE_EXTENSION }; // Full validation
    }
    setValidationSchema(newSchema);
  }, [showPassword]);

  const handleShowPasswordClick = useCallback(() => {
    setShowPassword((oldValue) => !oldValue);
  }, []);

  const handleAgreeClick = useCallback(() => {
    setAgree((oldValue) => !oldValue);
  }, []);

  const handleFormSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      const apiResult = await api?.auth?.signup(values);

      if (!apiResult) {
        setError('Can not create user for given email, if you already have account please sign in');
        return; // Unsuccessful signup
      }

      dispatch({ type: SIGN_UP });
      navigate('/', { replace: true });
    },
    [dispatch, values, navigate]
  );

  const handleCloseError = useCallback(() => setError(undefined), []);

  if (loading) return <LinearProgress />;

  return (
    <AppForm onSubmit={handleFormSubmit}>
      <Autocomplete
        id="country-select-demo"
        sx={{ width: 300 }}
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        options={[
          { code: 'AD', label: 'Andorra', phone: '376' },
          {
            code: 'AE',
            label: 'United Arab Emirates',
            phone: '971',
          },
          { code: 'AF', label: 'Afghanistan', phone: '93' },
        ]}
        autoHighlight
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <AppTextField
            {...params}
            placeholder="Search..."
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="end">
                  <AppIconButton
                    color={'info'}
                    sx={{ padding: 0 }}
                    aria-label="toggle password visibility"
                    icon={'search'}
                    onClick={handleShowPasswordClick}
                    onMouseDown={eventPreventDefault}
                  />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
      <AppTextField
        placeholder="Search..."
        name="email"
        InputProps={{
          startAdornment: (
            <InputAdornment position="end">
              <AppIconButton
                color={'info'}
                sx={{ padding: 0 }}
                aria-label="toggle password visibility"
                icon={'search'}
                onClick={handleShowPasswordClick}
                onMouseDown={eventPreventDefault}
              />
            </InputAdornment>
          ),

          endAdornment: values.email && (
            <InputAdornment position="end">
              <AppIconButton
                color={'info'}
                sx={{ padding: 0 }}
                aria-label="toggle password visibility"
                icon={'close'}
                onClick={() => {
                  onFieldChange({ target: { name: 'email', value: '' } });
                }}
                onMouseDown={eventPreventDefault}
              />
            </InputAdornment>
          ),
        }}
        value={values.email}
        fieldHasError={fieldHasError}
        fieldGetError={fieldGetError}
        onFieldChange={onFieldChange}
      />

      <AppTextField
        required={true}
        label="Email"
        name="email"
        value={values.email}
        fieldHasError={fieldHasError}
        fieldGetError={fieldGetError}
        onFieldChange={onFieldChange}
      />
      <AppTextField
        required
        label="Phone"
        name="phone"
        value={values.phone}
        fieldHasError={fieldHasError}
        fieldGetError={fieldGetError}
        onFieldChange={onFieldChange}
        {...SHARED_CONTROL_PROPS}
      />
      <AppTextField
        required
        label="First Name"
        name="firstName"
        value={values.firstName}
        fieldHasError={fieldHasError}
        fieldGetError={fieldGetError}
        onFieldChange={onFieldChange}
        {...SHARED_CONTROL_PROPS}
      />
      <AppTextField
        required
        label="Last Name"
        name="lastName"
        value={values.lastName}
        fieldHasError={fieldHasError}
        fieldGetError={fieldGetError}
        onFieldChange={onFieldChange}
        {...SHARED_CONTROL_PROPS}
      />
      <AppTextField
        required
        type={showPassword ? 'text' : 'password'}
        label="Password"
        name="password"
        value={values.password}
        fieldHasError={fieldHasError}
        fieldGetError={fieldGetError}
        onFieldChange={onFieldChange}
        {...SHARED_CONTROL_PROPS}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <AppIconButton
                color={'info'}
                aria-label="toggle password visibility"
                icon={showPassword ? 'visibilityon' : 'visibilityoff'}
                title={showPassword ? 'Hide Password' : 'Show Password'}
                onClick={handleShowPasswordClick}
                onMouseDown={eventPreventDefault}
              />
            </InputAdornment>
          ),
        }}
      />
      {!showPassword && (
        <AppTextField
          required
          type="password"
          label="Confirm Password"
          name="confirmPassword"
          value={values.confirmPassword}
          fieldHasError={fieldHasError}
          fieldGetError={fieldGetError}
          onFieldChange={onFieldChange}
          {...SHARED_CONTROL_PROPS}
        />
      )}
      <FormControlLabel
        control={<Checkbox required name="agree" checked={agree} onChange={handleAgreeClick} />}
        label="You must agree with Terms of Use and Privacy Policy to use our service *"
      />

      {error ? (
        <AppAlert severity="error" onClose={handleCloseError}>
          {error}
        </AppAlert>
      ) : null}

      <Grid container justifyContent="center" alignItems="center">
        <AppButton type="submit" disabled={!(formState.isValid && agree)}>
          Confirm and Sign Up
        </AppButton>
      </Grid>
    </AppForm>
  );
};

export default SignupView;
