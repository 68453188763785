import React from 'react';
/**
 * Note: Don't import/export all Views directly, use lazy loading!
 */
import { withSuspense } from '../components';
import NotFoundView from './NotFoundView';
import NotImplementedView from './NotImplementedView';

/**
 * Views/Pages with Lazy Loading
 */
const FanFeedPage = withSuspense(React.lazy(() => import('./Feed')));
const FollowingPage = withSuspense(React.lazy(() => import('./Following')));
const ProfilePage = withSuspense(React.lazy(() => import('./Profile')));
const ArtistProfilePage = withSuspense(React.lazy(() => import('./ArtistProfile')));
const AccountPage = withSuspense(React.lazy(() => import('./Account')));
const ActivityPage = withSuspense(React.lazy(() => import('./Activity')));
const SettingsPage = withSuspense(React.lazy(() => import('./Settings')));
const SubscriptionPage = withSuspense(React.lazy(() => import('./Subscription')));
const LinkedSocialsPage = withSuspense(React.lazy(() => import('./LinkedSocials')));
const DiscoverPage = withSuspense(React.lazy(() => import('./Discover')));
const FavouritesPage = withSuspense(React.lazy(() => import('./Favourites')));
const WelcomePage = withSuspense(React.lazy(() => import('./Welcome')));
const OnboardingPage = withSuspense(React.lazy(() => import('./Onboarding')));
const ConnectSocialsPage = withSuspense(React.lazy(() => import('./ConnectSocials')));
const ResyncSocialsPage = withSuspense(React.lazy(() => import('./ResyncSocials')));
const MessagingPage = withSuspense(React.lazy(() => import('./Messaging')));
const AboutView = withSuspense(React.lazy(() => import('./About')));
const ToolsView = () => <NotImplementedView name="Following" />; // Sample of non-implemented View
const UserView = () => <NotImplementedView name="Messages" />; // Sample of non-implemented View

export {
  NotFoundView,
  FollowingPage,
  FavouritesPage,
  MessagingPage,
  ResyncSocialsPage,
  DiscoverPage,
  AccountPage,
  SettingsPage,
  OnboardingPage,
  ProfilePage,
  LinkedSocialsPage,
  SubscriptionPage,
  AboutView,
  ArtistProfilePage,
  ConnectSocialsPage,
  ActivityPage,
  WelcomePage,
  FanFeedPage,
  UserView,
  ToolsView,
};
